<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("settings.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row mx-2">
            <div class="col-md-12">
              <b-tabs content-class="mt-3" class="user-setting">
                <b-tab
                  :title="$t('settings.ia.title')"
                  title-link-class="user-setting-tabs"
                  active
                >
                  <form @submit="submitProfil">
                    <section class="row">
                      <div class="col-md-9">
                        <div class="ssp-18-600">
                          {{ $t("settings.ia.note1") }}
                        </div>
                        <div class="ssp-18-400">
                          {{ $t("settings.ia.note2") }}
                        </div>
                      </div>
                      <div class="col-md-3">
                        <span
                          v-if="!isEdit"
                          class="btn btn-success float-right"
                          style="border-radius: 8px;"
                          @click="handleEdit"
                        >
                          {{ $t("settings.edit") }}
                        </span>
                        <button
                          v-if="isEdit"
                          type="submit"
                          class="btn btn-success float-right"
                          style="border-radius: 8px;"
                        >
                          <span
                            v-if="loading"
                            class="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                            style="margin-bottom: 1px;"
                          ></span>
                          {{ $t("settings.save") }}
                        </button>
                      </div>
                    </section>
                    <hr />
                    <section class="row">
                      <div class="col-md-2">
                        <div class="ssp-16 mb-1">
                          {{ $t("settings.ia.photo") }}
                        </div>
                        <div class="pp-wrapper">
                          <img
                            v-if="ppURL === 'null'"
                            src="@/assets/img/upl-profil.png"
                            alt="Profile"
                          />
                          <img
                            v-else
                            :src="url + ppURL"
                            :alt="ppURL"
                            style="border-radius: 10px"
                          />
                        </div>
                        <div v-if="isEdit" class="small mt-2">
                          {{ $t("settings.ia.note3") }}
                        </div>
                        <input
                          v-if="isEdit"
                          type="file"
                          @change="handleUpload"
                        />
                      </div>
                      <div class="col-md-5">
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            {{ $t("settings.ia.name") }}
                          </label>
                          <input
                            type="text"
                            class="form-control mb-1"
                            :class="{
                              'invalid-form': $v.updateProfil.name.$error
                            }"
                            v-model="$v.updateProfil.name.$model"
                            :disabled="!isEdit"
                          />
                          <div
                            class="text-sm text-red"
                            v-if="$v.updateProfil.name.$error"
                          >
                            <div v-if="!$v.updateProfil.name.required">
                              {{ $t("feedback.required") }}
                            </div>
                          </div>
                        </div>
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            {{ $t("settings.ia.phone") }}
                          </label>
                          <div class="input-group">
                            <div
                              class="input-group-prepend"
                              style="background-color: #e9ecef;"
                            >
                              <span
                                class="input-group-text text-black"
                                id="basic-addon1"
                                >+62</span
                              >
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              :class="{
                                'invalid-form': $v.updateProfil.hp.$error
                              }"
                              v-model="$v.updateProfil.hp.$model"
                              :disabled="!isEdit"
                            />
                          </div>
                          <div
                            class="text-sm text-red"
                            v-if="$v.updateProfil.hp.$error"
                          >
                            <div v-if="!$v.updateProfil.hp.required">
                              {{ $t("feedback.required") }}
                            </div>
                            <div v-if="!$v.updateProfil.hp.numeric">
                              {{ $t("feedback.invalid") }}
                            </div>
                            <div v-if="!$v.updateProfil.hp.maxLength">
                              {{ $t("feedback.exceeds") }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            {{ $t("settings.ia.ugdn") }}
                          </label>
                          <input
                            type="text"
                            class="form-control mb-1"
                            disabled
                            v-model="updateProfil.ugdn"
                          />
                        </div>
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            {{ $t("settings.ia.email") }}
                          </label>
                          <input
                            type="text"
                            class="form-control mb-1"
                            disabled
                            v-model="updateProfil.email"
                          />
                        </div>
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            {{ $t("settings.ia.role") }}
                          </label>
                          <input
                            type="text"
                            class="form-control mb-1"
                            disabled
                            v-model="updateProfil.role"
                          />
                        </div>
                      </div>
                    </section>
                    <!-- <hr />
                    <section class="row">
                      <div class="col-md-2">
                        <div class="ssp-16 mb-1">Wilayah</div>
                      </div>
                      {{ detailWilayah }}
                      <div class="col-md-5">
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            Country
                          </label>
                          <input
                            type="text"
                            class="form-control mb-1"
                            v-model="detailWilayah.country"
                            disabled
                          />
                        </div>
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            Region
                          </label>
                          <input
                            type="text"
                            class="form-control mb-1"
                            v-model="detailWilayah.region"
                            disabled
                          />
                        </div>
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            Province
                          </label>
                          <input
                            type="text"
                            class="form-control mb-1"
                            v-model="detailWilayah.province"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            Kabupaten
                          </label>
                          <input
                            type="text"
                            class="form-control mb-1"
                            v-model="detailWilayah.country"
                            disabled
                          />
                        </div>
                      </div>
                    </section> -->
                  </form>
                </b-tab>
                <b-tab
                  :title="$t('settings.cp.title')"
                  title-link-class="user-setting-tabs"
                >
                  <section class="row">
                    <div class="col-md-12 ">
                      <div class="ssp-18-600">
                        {{ $t("settings.cp.note1") }}
                      </div>
                      <div class="ssp-18-400">
                        {{ $t("settings.cp.note2") }}
                      </div>
                    </div>
                  </section>
                  <hr />
                  <section>
                    <form class="row" @submit="submitPassword">
                      <div class="col-md-6">
                        <div class="form-group mb-4">
                          <label class="ssp-16-400 text-black">
                            {{ $t("settings.cp.old") }}
                          </label>
                          <input
                            type="password"
                            class="form-control mb-1"
                            v-model="$v.updatePassword.oldPass.$model"
                            :class="{
                              'invalid-form': $v.updatePassword.oldPass.$error
                            }"
                            :placeholder="$t('settings.cp.oldpc')"
                          />
                          <div
                            class="text-sm text-red"
                            v-if="$v.updatePassword.oldPass.$error"
                          >
                            <div v-if="!$v.updatePassword.oldPass.required">
                              {{ $t("feedback.required") }}
                            </div>
                          </div>
                        </div>
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            {{ $t("settings.cp.new") }}
                          </label>
                          <input
                            type="password"
                            class="form-control mb-1"
                            v-model="$v.updatePassword.newPass.$model"
                            :class="{
                              'invalid-form': $v.updatePassword.newPass.$error
                            }"
                            :placeholder="$t('settings.cp.newpc')"
                          />
                          <div
                            class="text-sm text-red"
                            v-if="$v.updatePassword.newPass.$error"
                          >
                            <div v-if="!$v.updatePassword.newPass.required">
                              {{ $t("feedback.required") }}
                            </div>
                            <div v-if="!$v.updatePassword.newPass.minLength">
                              {{ $t("feedback.min8") }}
                            </div>
                          </div>
                        </div>
                        <div class="form-group mb-2">
                          <label class="ssp-16-400 text-black">
                            {{ $t("settings.cp.conf") }}
                          </label>
                          <input
                            type="password"
                            class="form-control mb-1"
                            v-model="$v.updatePassword.confPass.$model"
                            :class="{
                              'invalid-form': $v.updatePassword.confPass.$error
                            }"
                            :placeholder="$t('settings.cp.confpc')"
                          />
                          <div
                            class="text-sm text-red"
                            v-if="$v.updatePassword.confPass.$error"
                          >
                            <div v-if="!$v.updatePassword.confPass.required">
                              {{ $t("feedback.required") }}
                            </div>
                            <div
                              v-if="!$v.updatePassword.confPass.sameAsPassword"
                            >
                              {{ $t("feedback.unmatch") }}
                            </div>
                          </div>
                        </div>
                        <button type="submit" class="btn btn-success mt-2 mb-2">
                          {{ $t("settings.upd_pass") }}
                        </button>
                      </div>
                    </form>
                  </section>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import axios from "axios";
import JwtService from "@/core/services/jwt.service";
import { M_EDIT_PASSWORD, M_EDIT_USER } from "@/graphql/mutations";
import { detailWilayahQuery } from "@/graphql/queries";
import { RoleType } from "@/graphql/enum.type";

import {
  required,
  minLength,
  sameAs,
  numeric,
  maxLength
} from "vuelidate/lib/validators";
export default {
  components: {
    Card
  },
  data() {
    return {
      id: JwtService.getID(),
      optionRole: [RoleType.TSS],
      detailUser: JSON.parse(JwtService.getDetail()),
      loading: false,
      isEdit: false,
      updateProfil: {
        name: JwtService.getName(),
        hp: JwtService.getPhone().slice(3),
        ugdn: JwtService.getUGDN(),
        email: JwtService.getEmail(),
        role: this.convertRoles(JwtService.getRole()),
        ppFile: null,
        ppName: null
      },
      detailWilayah: [],
      updatePassword: {
        oldPass: "",
        newPass: "",
        confPass: ""
      },
      ppURL: JwtService.getPP(),
      url: JwtService.getURL()
    };
  },
  apollo: {
    detailUser: {
      query: () => detailWilayahQuery,
      variables() {
        return {
          id: JwtService.getID()
        };
      },
      result({ data }) {
        this.detailWilayah = data.detailUser.detail;
      }
    }
  },
  validations: {
    updatePassword: {
      oldPass: { required },
      newPass: {
        required,
        minLength: minLength(8)
      },
      confPass: {
        required,
        sameAsPassword: sameAs("newPass")
      }
    },
    updateProfil: {
      name: { required },
      hp: { required, numeric, maxLength: maxLength(14) }
    }
  },
  methods: {
    handleEdit() {
      this.isEdit = true;
    },
    handleUpload(ev) {
      const files = ev.target.files[0];
      if (
        !(
          files.type === "image/png" ||
          files.type === "image/jpg" ||
          files.type === "image/jpeg"
        )
      ) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Pastikan foto memiliki ekstensi jpg, jpeg, dan png",
          width: 400,
          timer: 2000
        });
      } else {
        if (files.size >= 2000000) {
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Ukuran foto maksimal adalah 2 Mb",
            width: 400,
            timer: 2000
          });
        } else {
          // this.ppURL = URL.createObjectURL(files);
          this.updateProfil.ppFile = files;
        }
      }
    },
    resetProfileField() {
      this.updateProfil = {
        name: "",
        hp: ""
      };
      this.$v.updateProfil.$reset();
    },
    async submitProfil(evt) {
      evt.preventDefault();
      let uploadPic = false;
      let phone = null;
      if (this.updateProfil.hp.charAt(0) === "0") {
        phone = `+62${this.updateProfil.hp.slice(1)}`;
      } else if (this.updateProfil.hp.charAt(0) !== "+") {
        phone = `+62${this.updateProfil.hp}`;
      } else {
        phone = this.updateProfil.hp;
      }
      this.$v.updateProfil.$touch();
      if (!this.$v.updateProfil.$error) {
        this.loading = true;
        if (this.updateProfil.ppFile !== null) {
          let formData = new FormData();
          formData.append("file", this.updateProfil.ppFile);
          formData.append("tipe", "profile");
          await axios
            .post(`${process.env.VUE_APP_URL_GRAPHQL}/image/upload`, formData, {
              headers: {
                Authorization: "Bearer " + JwtService.getToken(),
                "Content-Type": "multipart/form-data"
              }
            })
            .then(response => {
              if (response.data.message === "success") {
                const data = response.data.data;
                this.ppURL = data.replace("dashboard", "api");
                uploadPic = true;
              }
            });
        }
        // console.log(this.updateProfil.ppName);
        return new Promise((resolve, reject) => {
          const updProfile = this.$apollo.mutate({
            mutation: M_EDIT_USER,
            variables: {
              id: JwtService.getID(),
              name: this.updateProfil.name,
              phone: phone,
              email: this.updateProfil.email,
              ugdn: this.updateProfil.ugdn,
              role: JwtService.getRole(),
              profile: this.ppURL,
              detail: []
            }
          });
          if (resolve) {
            resolve(updProfile);
          } else {
            reject(updProfile);
          }
        })
          .then(response => {
            if (response) {
              this.toastAlert(this.$t("alert.save"));
              JwtService.saveName(response.data.updateUser.name);
              JwtService.savePhone(response.data.updateUser.phone);
              JwtService.savePP(response.data.updateUser.profile);
              this.updateProfil.hp = response.data.updateUser.phone.slice(3);
              this.loading = false;
              this.isEdit = false;
              if (uploadPic) window.location.reload();
              uploadPic = false;
            }
          })
          .catch(err => {
            this.$swal.fire({
              icon: "error",
              title: "Error!",
              text: err.message,
              width: 400,
              confirmButtonText: "OK"
            });
            this.loading = false;
          });
      }
    },
    resetPassField() {
      this.updatePassword = {
        oldPass: "",
        newPass: "",
        confPass: ""
      };
      this.$v.updatePassword.$reset();
    },
    submitPassword(evt) {
      evt.preventDefault();
      this.$v.updatePassword.$touch();
      if (!this.$v.updatePassword.$error) {
        return new Promise((resolve, reject) => {
          const updPass = this.$apollo.mutate({
            mutation: M_EDIT_PASSWORD,
            variables: {
              id: this.id,
              old: this.updatePassword.oldPass,
              pass: this.updatePassword.newPass
            }
          });
          if (resolve) {
            resolve(updPass);
          } else {
            reject(updPass);
          }
        })
          .then(response => {
            if (response) {
              this.toastAlert(this.$t("alert.save"));
              this.resetPassField();
            }
          })
          .catch(err => {
            if (err.message === "GraphQL error: Error: Incorrect password") {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: "Incorrect password!",
                width: 400,
                confirmButtonText: "OK"
              });
            } else {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            }
            this.updatePassword.oldPass = "";
            this.$v.updatePassword.$reset();
          });
      }
    }
  },
  mounted() {}
};
</script>

<style>
.ssp-18-600 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.ssp-18-400 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #c4c4c4;
}

.user-setting-tabs.active {
  color: #000 !important;
  /* background-color: #f3f3f3 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important; */
  border-bottom: 2px solid #fd8833 !important;
}

.user-setting-tabs {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #273b4a;
}
</style>
